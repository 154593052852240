import React, { useState } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import axios from 'axios';
import { map } from 'lodash';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import Layout from '../components/layout';

import '../styles/contact.css';

const Contact = () => (
  <StaticQuery
    query={graphql`
      {
        hope {
          allStates {
            abbreviation
          }
        }
      }
    `}
    render={data => {
      const states = map(data.hope.allStates, 'abbreviation');
      const [validated, setValidated] = useState(false);
      const [submitted, setSubmitted] = useState(false);
      const [contactData, setContactData] = useState({});

      const handleSubmit = async event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity()) {
          await axios.post('https://cms.hope.axismaps.io/email', contactData);
          setSubmitted(true);
        }

        setValidated(true);
      };

      const handleChange = event => {
        const { name, value } = event.currentTarget;
        const newData = { ...contactData };
        newData[name] = value;
        setContactData(newData);
      };

      return (
        <Layout>
          <Container>
            <h1>Contact Us</h1>
            {submitted ? (
              <h3>Thank you for your inquiry.</h3>
            ) : (
              <Form
                className="contact-form"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Row>
                  <p>
                    HOPE is committed to seeing our data make a difference. We want to connect with
                    you. Please share your inquiries, feedback, and stories.
                  </p>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridFirstName" md={4}>
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control name="First Name" type="text" onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridLastName" md={4}>
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control name="Last Name" type="text" onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridEmail" md={4}>
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control name="Email" type="email" onChange={handleChange} required />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridCity" md={4}>
                    <Form.Label>City*</Form.Label>
                    <Form.Control name="City" type="text" onChange={handleChange} required />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridState" md={4}>
                    <Form.Label>State*</Form.Label>
                    <Form.Control as="select" custom name="State" onChange={handleChange} required>
                      <>
                        <option aria-label="Select a state" />
                        {states.map(s => (
                          <option key={s} value={s}>
                            {s}
                          </option>
                        ))}
                      </>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridOrganization" md={4}>
                    <Form.Label>Organization</Form.Label>
                    <Form.Control name="Organization" type="text" onChange={handleChange} />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridMessage">
                    <Form.Label>Message*</Form.Label>
                    <Form.Control name="Message" as="textarea" onChange={handleChange} required />
                  </Form.Group>
                </Form.Row>
                <Button variant="outline-dark" type="submit" block>
                  SUBMIT
                </Button>
              </Form>
            )}
          </Container>
        </Layout>
      );
    }}
  />
);

export default Contact;
